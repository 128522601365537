import * as React from "react"
import { Stakeholders } from "utils/StakeholdersInfo"
import OrganiserImageCard from "components/OrganiserImageCard"

function Organisers() {
	return (
		<div className="organisers__container" data-testid="organisers">
			<div className="organisers__heading">Branding</div>
			<div className="organisers__row">
				<OrganiserImageCard {...Stakeholders.ghantasala} className="organisers__column" />
				<OrganiserImageCard {...Stakeholders.parvath} className="organisers__column" />
			</div>

			<div className="organisers__heading">Community Engagement</div>
			<div className="organisers__row">
				<OrganiserImageCard {...Stakeholders.parvath} className="organisers__column" />
				<OrganiserImageCard {...Stakeholders.varshith} className="organisers__column" />
			</div>
			<div className="organisers__heading">Diversity and Inclusion</div>
			<div className="organisers__row">
				<OrganiserImageCard {...Stakeholders.himanshi} className="organisers__column" />
				<OrganiserImageCard {...Stakeholders.chandhana} className="organisers__column" />
				<OrganiserImageCard {...Stakeholders.ishani} className="organisers__column" />
				<OrganiserImageCard {...Stakeholders.nandhakishore} className="organisers__column" />
				<OrganiserImageCard {...Stakeholders.thivyaa} className="organisers__column" />
			</div>

			<div className="organisers__heading">Engineering</div>
			<div className="organisers__row">
				<OrganiserImageCard {...Stakeholders.darshana} className="organisers__column" />
				<OrganiserImageCard {...Stakeholders.chirag} className="organisers__column" />
				<OrganiserImageCard {...Stakeholders.shurya} className="organisers__column" />
				<OrganiserImageCard {...Stakeholders.akriti} className="organisers__column" />
			</div>

			<div className="organisers__heading">Fundraising</div>
			<div className="organisers__row">
				<OrganiserImageCard {...Stakeholders.darshan} className="organisers__column" />
				<OrganiserImageCard {...Stakeholders.himanshi} className="organisers__column" />
			</div>
			<div className="organisers__heading">Mentor Relations</div>
			<div className="organisers__row">
				<OrganiserImageCard {...Stakeholders.vaikundaraman} className="organisers__column" />
				<OrganiserImageCard {...Stakeholders.harshavardhan} className="organisers__column" />
			</div>

			<div className="organisers__heading">Outreach</div>
			<div className="organisers__row">
				<OrganiserImageCard {...Stakeholders.vivek} className="organisers__column" />
				<OrganiserImageCard {...Stakeholders.shakti} className="organisers__column" />
			</div>
		</div>
	)
}

export default Organisers
