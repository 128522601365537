export const NittBranches = {
	CSE: "Computer Science and Engineering",
	EEE: "Electrical and Electronics Engineering",
	ECE: "Electronics and Communication Engineering",
	ICE: "Instrumentation and Control Engineering",
	MEC: "Mechanical Engineering",
	MME: "Metallurgical and Materials Engineering",
	CIV: "Civil Engineering",
	PRO: "Production Engineering",
	CHE: "Chemical Engineering" 
}