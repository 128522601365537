export const applicationStages = [
	{
		id: 1,
		name: "Interest form deadline",
		date: "December 30, 2023"
	},
	{
		id: 2,
		name: "Application Deadline",
		date: "May 4, 2024"
	},
	{
		id: 3,
		name: "Shortlist Announcement",
		date: "May, 2024"
	},
	{
		id: 4,
		name: "Interviews",
		date: "June, 2024"
	}
]