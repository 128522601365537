import * as React from "react"
import { useEffect } from "react"
import { NavLink } from "react-router-dom"
import mentorShipImage from "../assets/mentor-oh-vs-mentorship-session.png"

function Resources() {
	useEffect(() => {
		document.title = "Resources"
	})
	return (
		<div className="page page--resources" data-testid="resources">
			<div className="page__container col-8">
				<p>If you do not have a laptop and/or have limited internet connection, here is a document with some resources for you:</p>
				<br />
				<br />
				<div className="page__align-container">
					<a className="page__link page__link--highlighted" href="https://docs.google.com/document/d/1zJ-DwppSnR_plE9TkNdjAEuaEPFJXkxsQ8M-YFW_96U/edit?usp=sharing" target='_blank' rel='noreferrer'>Click here for resources for no laptop / limited internet</a>
				</div>
				<br />
				<br />
				<p>If you have a question about the scholarship:</p>
				<ol className="page__list">
					<li>Check our <a className="page__link" href="https://www.youtube.com/watch?v=dJCTMKIDa8Q&list=PLdzmOsLIXWdwBP6Zv9PwkUTtrd6I5PEen" target='_blank' rel='noreferrer'>briefing videos</a> and the <NavLink to='/faq' data-testid="link-to-faq" className="page__link">FAQ</NavLink></li>
					<li>If it is not answered there, reach out on <a className="page__link" href="https://www.facebook.com/jrscholarshipnitt" target="_blank" rel="noreferrer">Facebook</a>, <a className="page__link" href="https://www.instagram.com/jrscholarshipnitt/" target="_blank" rel="noreferrer">Instagram</a> or email us at <a className="page__link" href="mailto:info@jrscholarship.org">info@jrscholarship.org</a></li>
					<li>If you do not get a response within one day, send us a message on WhatsApp at +91 9626883116</li>
				</ol>
				<p>Each mentor will host one in-person session (called &apos;Mentor Office Hours&apos;) or online webinar focussing on a specific element of the scholarship (called &apos;Mentorship session&apos;). You are expected to attend them and ask / &quot;comment&quot; any questions you might have, which the mentor will then answer.</p>
				<br />
				<br />
				<div className="page__align-container">
					<img style={{ width: "90vmin" }} src={mentorShipImage}></img>
				</div>
				<br />
				<br />
				<p>For past Mentorship sessions, check the link below:</p>
				<br />
				<br />
				<div className="page__align-container">
					<a href="https://www.youtube.com/playlist?list=PLdzmOsLIXWdzpD8gjQJbD3fSyjVtXWnPe" className="page__link page__link--highlighted" target="_blank" rel="noreferrer">Click here for the Mentorship playlist</a>
				</div>
				<br />
				<br />
				<p>You can also send your essays and project abstracts for review to the designated mentors for feedback. Please provide them at least 1 week for the recipient mentor to respond.</p>
				<br />
				<p>College can be a stressful time for many. Here are some resources to help you along the way:</p>
				<br />
				<br />
				<div className="page__align-container">
					<a href="https://www.youtube.com/playlist?list=PLdzmOsLIXWdzpD8gjQJbD3fSyjVtXWnPe" className="page__link page__link--highlighted" target="_blank" rel="noreferrer">Click here for JSPF Self care booklet</a>
				</div>
				<br />
				<br />
				<div style={{ color: "#2a2a2a" }}>
					<h1>Find projects related to Your Department</h1>
					<ul className="page__list">
						<li>Email professors in your department expressing interest</li>
						<li>Brainstorm with mentors and finalists from your department for project ideas.</li>
						<li>Take up in-plant training at an organization of your interest</li>
						<li>Use your favorite search engine to look for programs/internships geared towards freshmen</li>
					</ul>
				</div>
				<br />
				<br />
				<div style={{ color: "#2a2a2a" }}>
					<h1>Other scholarships:</h1>
					<p>Here are some other scholarships which you can refer to as well as apply for if/when you are eligible:</p>
					<ul className="page__list">
						<li>Chevening Scholarships for UK universities: <a href="http://www.chevening.org/india/" className="page__link" target="_blank" rel="noreferrer">http://www.chevening.org/india/</a></li>
						<li>Fulbright Scholarship for US universities: <a href="http://www.usief.org.in/" className="page__link" target="_blank" rel="noreferrer">http://www.usief.org.in/</a></li>
						<li>Gates Cambridge Scholarship, University of Cambridge: <a href="https://www.gatescambridge.org/" className="page__link" target="_blank" rel="noreferrer">https://www.gatescambridge.org/</a></li>
						<li>JN Tata Endowment for Higher Education: <a href="http://www.jntataendowment.org/" className="page__link" target="_blank" rel="noreferrer">http://www.jntataendowment.org/</a></li>
						<li>KC Mahindra Scholarship: <a href="https://www.kcmet.org/what-we-do-scholarship-grants.aspx" className="page__link" target="_blank" rel="noreferrer">https://www.kcmet.org/what-we-do-scholarship-grants.aspx</a></li>
						<li>Palantir Scholarships: <a href="https://www.palantir.com/students/scholarship/" className="page__link" target="_blank" rel="noreferrer">https://www.palantir.com/students/scholarship/</a></li>
						<li>Rhodes Scholarship, University of Oxford: <a href="https://www.rhodeshouse.ox.ac.uk/" className="page__link" target="_blank" rel="noreferrer">https://www.rhodeshouse.ox.ac.uk/</a></li>
						<li>Schwarzman Scholarship, Tsinghua University: <a href="https://www.schwarzmanscholars.org/" className="page__link" target="_blank" rel="noreferrer">https://www.schwarzmanscholars.org/</a></li>
					</ul>
				</div>
			</div>
		</div>
	)
}

export default Resources
