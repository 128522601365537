import * as React from "react"
import { useEffect } from "react"
import { NavLink } from "react-router-dom"

function GettingStarted() {
	useEffect(() => {
		document.title = "Getting Started"
	}, [])

	return (
		<div className="page" data-testid="getting-started">
			<div className="col-9 page__container">
				<h1 className="gs__header">Oh, the Places You&apos;ll Go!</h1>
				<div className="gs__image-container">
					<div>
						<img
							data-testid="gs-image"
							className="gs__img"
							src="images/img-getting-started.jpeg"
						></img>
					</div>
					<div className="gs__right-container">
						<div>
							Congratulations on taking the first step towards becoming a
							Jitheshraj Scholar! It is an arduous and transformative journey
							that brings to you extraordinary benefits.
						</div>
					</div>
				</div>
				
				<br />
				<div>
					Follow the steps below to help us help you through the
					process:
				</div>
				<br />
				<div>
					<ol>
						<li>
							<a
								className="page__link"
								href="https://docs.google.com/document/d/1gUvz0msCJn_p7jxgpl4bb5R2LnXmimS36ytAc_N2sOg/edit"
								target="_blank"
								rel="noreferrer"
							>
								Read through our code of conduct to understand expectations
							</a>{" "}
						</li>
						<li>
							Fill in the{" "}
							<a
								className="page__link"
								href="https://docs.google.com/forms/d/e/1FAIpQLSeZCJx4Dmj2TjDA9odS_B3o5k9oNjH1zln9O2ak3EW1kvLTpA/viewform"
								target="_blank"
								rel="noreferrer"
							>
								Interest form
							</a>
							.
						</li>
						<li>
							Complete our{" "}
							<a
								className="page__link"
								href="https://link.edapp.com/sf2rUaRzbqb"
								target="_blank"
								rel="noreferrer"
							>
								EdApp modules
							</a>
							to know more about JSPF.
						</li>
						<li>
							Go through the application requirements listed in our{" "}
							<NavLink
								to="/requirements"
								data-testid="link-to-requirements"
								className="page__link"
							>
								Requirements page
							</NavLink>
							. These are to be completed and the relevant documents are to
							submitted by the deadline listed in our{" "}
							<NavLink
								to="/timeline"
								data-testid="link-to-timeline"
								className="page__link"
							>
								Timeline page
							</NavLink>
							.
						</li>
						<li>
							Join the{" "}
							<a
								className="page__link"
								href="https://t.me/jrscholarship_org"
								target="_blank"
								rel="noreferrer"
							>
								Telegram group
							</a>{" "}
							of prospective applicants.
						</li>
						<li>
							Follow our{" "}
							<a
								className="page__link"
								href="https://facebook.com/jrscholarship.org"
								target="_blank"
								rel="noreferrer"
							>
								Facebook page
							</a>
							,{" "}
							<a
								className="page__link"
								href="https://www.instagram.com/jrscholarship_org"
								target="_blank"
								rel="noreferrer"
							>
								Instagram account
							</a>{" "}
							(@jrscholarship_org) and our{" "}
							<a
								className="page__link"
								href="https://www.linkedin.com/company/jrscholarship-org/"
								target="_blank"
								rel="noreferrer"
							>
								LinkedIn page
							</a>{" "}
							for future updates on scheduling sessions, mentorship sessions and
							other important announcements.
						</li>
						<li>
							Go through the{" "}
							<NavLink
								to="/resources"
								data-testid="link-to-resources"
								className="page__link"
							>
								resources repository
							</NavLink>{" "}
							that contains guidelines and materials from past and current
							mentors.
						</li>
						<li>
							In case of questions, check out our{" "}
							<NavLink
								to="/faq"
								data-testid="link-to-faq"
								className="page__link"
							>
								Frequently Asked Questions
							</NavLink>{" "}
							section.
						</li>
						<li>
							In case of still-unanswered questions, mail us at{" "}
							<a className="page__link" href="mailto:info@jrscholarship.org">
								info@jrscholarship.org
							</a>
						</li>
					</ol>
				</div>
			</div>
		</div>
	)
}

export default GettingStarted
