const menuItems = [
	{
		name: "Home",
		children: null,
		routeTo: "/",
		testId: "home"
	},
	{
		name: "Who are we",
		children: [
			{
				name: "Our Objectives",
				children: null,
				testId:"our-objectives",
				routeTo: "/our-objectives"
			},
			{
				name: "Our Commitment",
				children: null,
				testId:"our-commitment",
				routeTo: "/our-commitment"
			}
		],
		routeTo: null,
		testId: "who-are-we"
	},
	{
		name: "People",
		testId: "people",
		children: [
			{
				name: "Finalists and Scholars",
				children: null,
				testId: "finalists-and-scholars",
				routeTo: "/finalists-and-scholars"
			},
			{
				name: "Team JSPF",
				testId:"team-jspf",
				children: [
					{
						name: "Leadership",
						children: null,
						testId: "leadership",
						routeTo: "/leadership"
					},
					{
						name: "Organisers",
						children: null,
						testId: "organisers",
						routeTo: "/organisers"
					},
					{
						name: "Mentors",
						children: null,
						testId: "Mentors",
						routeTo: "/mentors"
					},
					{
						name: "Selection and Interview Panel",
						children: null,
						testId: "selection-and-interview-panel",
						routeTo: "/selection-and-interview-panel"
					}
				],
				routeTo: null
			}
		]
	},
	{
		name: "Prospective Applicants",
		testId: "prospective-applicants",
		children: [
			{
				name: "Getting Started",
				children: null,
				testId: "getting-started",
				routeTo: "/getting-started"
			},
			{
				name: "Application Process",
				children: [
					{
						name: "Requirements",
						children: null,
						testId: "requirements",
						routeTo: "/requirements"
					},
					{
						name: "Timeline",
						children: null,
						testId: "timeline",
						routeTo: "/timeline"
					}
				]
			},
			{
				name: "Resources",
				children: null,
				testId: "resources",
				routeTo: "/resources"
			},
			{
				name: "Plagiarism and Citation",
				children: null,
				testId: "plagiarism-and-citation",
				routeTo: "/plagiarism-and-citation"
			},
			{
				name: "Application Checklist",
				testId: "application-checklist",
				children: null,
				routeTo: "/application-checklist"
			}
		],
		routeTo: null
	},
	{
		name: "Campus Ambassador",
		testId: "campus-ambassador",
		children: null,
		routeTo: "/campus-ambassador"
	},
	{
		name: "FAQ",
		testId: "faq",
		children: null,
		routeTo: "/faq"
	},
	{
		name: "Work with us",
		testId: "work-with-us",
		children: null,
		routeTo: "/work-with-us"
	},
	{
		name: "Donate",
		testId: "donate",
		children: null,
		routeTo: "/donate"
	},
]

export default menuItems