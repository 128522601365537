import { NittBranches } from "./NittBranches"
import { PRONOUNS } from "./Pronouns"

//Add in alphabetical order
export const Stakeholders = {
	akriti: {
		name: "Akriti Upadhyay",
		currentDesignation: "MCA, 2023, NIT Trichy",
		jspfDesignation: "Associate",
		education: "MCA, 2023, NIT Trichy",
		pronouns: PRONOUNS.SHE,
		image: "img-akriti-upadhyay.jpg",
		linkedin: "https://www.linkedin.com/in/akriti-upadhyay/",
		testid: "akriti",
	},
	ambrin: {
		name: "Ambrin",
		currentDesignation: "VP, Guidance Tamil Nadu",
		education: "Mechanical, 2011, NIT Trichy",
		pronouns: PRONOUNS.SHE,
		image: "img-ambrin.png",
		linkedin: "https://www.linkedin.com/in/ambrinikram/",
		testid: "ambrin",
	},
	aruniya: {
		name: "Aruniya Selladurai",
		currentDesignation: "AVP, TWCorp",
		education: "CSE, 2012, NIT Trichy",
		pronouns: PRONOUNS.SHE,
		image: "img-aruniya-selladurai.jpeg",
		linkedin: "https://www.linkedin.com/in/aruniya-selladurai-a8214a5b/",
		testid: "aruniya",
	},
	akshay: {
		name: "Akshay Vidwans",
		currentDesignation: "Global Market Segment Lead at Envirologix",
		education: "Mechanical, 2009, NIT Trichy",
		pronouns: PRONOUNS.HE,
		image: "img-akshay-vidwans.jpeg",
		linkedin: "https://www.linkedin.com/in/akshay-vidwans-90572756/",
		testid: "akshay",
	},
	chandhana: {
		name: "Chandhana R",
		jspfDesignation: "Associate",
		currentDesignation: "Civil, 2023, NIT Trichy",
		education: "Civil, 2023, NIT Trichy",
		pronouns: PRONOUNS.SHE,
		image: "img-chandhana-ravikumar.jpeg",
		linkedin: "https://www.linkedin.com/in/chandhana-ravikumar-a048b61a3/",
		testid: "chandhana",
	},
	chendur: {
		name: "Chendur Singaram",
		currentDesignation: "Engineer, RefleXion Medical",
		education: "Mechanical, 2017, NIT Trichy",
		pronouns: PRONOUNS.HE,
		image: "img-chendur-singaram.jpeg",
		linkedin: "https://www.linkedin.com/in/chendur-singaram/",
		testid: "chendur",
	},
	chirag: {
		name: "Chirag Vaswani",
		currentDesignation: "SDE2, Aurigo",
		jspfDesignation: "Associate",
		education: "ICE, 2020, NIT Trichy",
		pronouns: PRONOUNS.HE,
		image: "img-chirag-vaswani.jpeg",
		linkedin: "https://www.linkedin.com/in/chirag-vaswani-b63729128/",
		testid: "chirag",
	},
	darshana: {
		name: "Darshana V",
		jspfDesignation: "Associate",
		currentDesignation: "ICE, 2023, NIT Trichy",
		education: "ICE, 2023, NIT Trichy",
		pronouns: PRONOUNS.SHE,
		image: "img-darshana-v.jpeg",
		linkedin: "https://www.linkedin.com/in/darshana-v/",
		testid: "darshana",
	},
	devah: {
		name: "Devahdhanush V S",
		currentDesignation: "PhD Candidate",
		education: "Mechanical, 2015, NIT Trichy",
		pronouns: PRONOUNS.HE,
		image: "img-devahdhanush-vs.jpeg",
		linkedin: "https://www.linkedin.com/in/devahdhanush/",
		testid: "devah",
	},
	diksha: {
		name: "Diksha Gohlyan",
		currentDesignation: "Engineer, Voxel AI",
		education: "CSE, 2013, NIT Trichy",
		pronouns: PRONOUNS.SHE,
		image: "img-diksha-gohlyan.png",
		linkedin: "https://www.linkedin.com/in/diksha-gohlyan-697b81a5/",
		testid: "diksha",
	},
	harshinee: {
		name: "Harshinee Murali",
		currentDesignation: "Student",
		education: "B.Tech, NIT Trichy",
		pronouns: PRONOUNS.SHE,
		image: "img-harshinee-murali.jpeg",
		linkedin: "https://www.linkedin.com/in/harshinee-murali-50bab71b5/",
		testid: "harshinee",
	},
	himanshi: {
		name: "Himanshi Gupta",
		currentDesignation: "Associate Engineer, Micron",
		jspfDesignation: "Senior Associate",
		education: "ECE, 2021, NIT Trichy",
		pronouns: PRONOUNS.SHE,
		image: "img-himanshi-gupta.jpeg",
		linkedin: "https://www.linkedin.com/in/himanshi-gupta-469534160/",
		testid: "himanshi",
	},
	ishani: {
		name: "Ishani Srivastava",
		currentDesignation: "Software Engineer, Google",
		jspfDesignation: "Associate",
		education: "CSE, 2021, NIT Trichy",
		pronouns: PRONOUNS.SHE,
		image: "img-ishani-srivastava.jpeg",
		linkedin: "https://www.linkedin.com/in/ishani-srivastava-a38056164/",
		testid: "ishani",
	},
	kashif: {
		name: "Kashif Sheikh",
		currentDesignation: "Asst GM, AGPT",
		education: "EEE, 2009, NIT Trichy",
		pronouns: PRONOUNS.HE,
		image: "img-kashif-sheikh.jpeg",
		linkedin: "https://www.linkedin.com/in/kashif-sheikh-ks/",
		testid: "kashif",
	},
	lakshmim: {
		name: "Lakshmi M",
		jspfDesignation: "Associate",
		currentDesignation: "ML Engineer, Pinterest",
		education: "CSE, 2016, NIT Trichy",
		pronouns: PRONOUNS.SHE,
		image: "img-lakshmi-manoharan.jpeg",
		linkedin: "https://www.linkedin.com/in/lakshmimanoharan/",
		testid: "lakshmi-m",
	},
	lakshmis: {
		name: "Lakshmi S",
		currentDesignation: "Faculty, Srishti Manipal",
		education: "Architecture, 2014, NIT Trichy",
		pronouns: PRONOUNS.SHE,
		image: "img-lakshmi-s.jpeg",
		linkedin: "https://www.linkedin.com/in/lakshmijayashree/",
		testid: "lakshmi-s",
	},
	miruthula: {
		name: "Miruthula J",
		jspfDesignation: "Associate",
		currentDesignation: "ECE, 2023, NIT Trichy",
		education: "ECE, 2023, NIT Trichy",
		pronouns: PRONOUNS.SHE,
		image: "img-miruthula-jayapraksham.jpeg",
		linkedin: "https://www.linkedin.com/in/miruthula-jayapraksham-92943819b/",
		testid: "miruthula",
	},
	nandhakishore: {
		name: "Nandhakishore C S",
		jspfDesignation: "Associate",
		currentDesignation: "Graduate Student, IIT Madras",
		education: NittBranches.CSE,
		pronouns: PRONOUNS.HE,
		image: "img-nandhakishore-cs.jpeg",
		linkedin: "https://www.linkedin.com/in/nandhakishorecs/",
		testid: "nandhakishore",
	},
	nida: {
		name: "Nida Maryam",
		jspfDesignation: "Associate",
		currentDesignation: "Mechanical, 2024, NIT Trichy",
		education: "Mechanical, 2024, NIT Trichy",
		pronouns: PRONOUNS.SHE,
		image: "img-nida-maryam.jpeg",
		linkedin: "https://www.linkedin.com/in/nida-maryam/",
		testid: "nida",
	},
	parvath: {
		name: "Parvath C",
		jspfDesignation: "Associate",
		currentDesignation: "Civil, 2024, NIT Trichy",
		education: "Civil, 2024, NIT Trichy",
		pronouns: PRONOUNS.HE,
		image: "img-parvath-c.jpeg",
		linkedin: "https://www.linkedin.com/in/parvath-c-nitt/",
		testid: "parvath",
	},
	pawan: {
		name: "Pawan Kaipa",
		currentDesignation: "State Manager, Gramin Healthcare",
		education: "EEE, 2011, NIT Trichy",
		pronouns: PRONOUNS.HE,
		image: "img-pawan-kaipa.jpeg",
		linkedin: "https://www.linkedin.com/in/pawankaipa/",
		testid: "pawan",
	},
	pranav: {
		name: "Pranav Chheda",
		currentDesignation: "Sourcing Manager, Apple",
		education: "Civil, 2013, NIT Trichy",
		pronouns: PRONOUNS.HE,
		image: "img-pranav-chheda.jpeg",
		linkedin: "https://www.linkedin.com/in/pchheda/",
		testid: "pranav",
	},
	prashanthi: {
		name: "Prashanthi Ravichandran",
		currentDesignation: "Ph.D in Biomedical Engineering, Johns Hopkins",
		education: "Mechanical, 2014, NIT Trichy",
		pronouns: PRONOUNS.HE,
		image: "img-prashanthi-ravichandran.jpeg",
		linkedin: "https://www.linkedin.com/in/prashanthi-ravichandran-96a363a0/",
		testid: "prashanthi",
	},
	purnima: {
		name: "Purnima U",
		currentDesignation: "Design Engineer, Synopsys",
		education: "EEE, 2021, NIT Trichy",
		pronouns: PRONOUNS.SHE,
		image: "img-purnima-unnikrishnan.jpeg",
		linkedin: "https://www.linkedin.com/in/purnima-unnikrishnan/",
		testid: "purnima",
	},
	rahul: {
		name: "Rahul Bhope",
		currentDesignation: "PhD Student, UC Irvine",
		education: "ICE, 2020, NIT Trichy",
		pronouns: PRONOUNS.HE,
		image: "img-rahul-bhope.jpeg",
		linkedin: "https://www.linkedin.com/in/rahul-bhope-3a0976130/",
		testid: "rahul",
	},
	shurya: {
		name: "Shurya Kumar",
		currentDesignation: "MTS4, Nutanix",
		jspfDesignation: "Associate",
		education: "CSE, 2017, NIT Trichy",
		pronouns: PRONOUNS.HE,
		image: "img-shurya-kumar.jpeg",
		linkedin: "https://www.linkedin.com/in/shurya-kumar-82023798/",
		testid: "shurya",
	},
	sidchan: {
		name: "Siddhant Chandra",
		currentDesignation: "UPSC Aspirant",
		jspfDesignation: "Lead Associate",
		education: "Civil, 2016, NIT Trichy",
		pronouns: PRONOUNS.HE,
		image: "img-siddhant-chandra.jpeg",
		linkedin: "https://www.linkedin.com/in/siddhant-chandra-5a8a0667/",
		testid: "sidchan",
	},
	sivaraj: {
		name: " B K Sivaraj",
		jspfDesignation: "Associate",
		currentDesignation: NittBranches.MME,
		education: NittBranches.MME,
		pronouns: PRONOUNS.HE,
		image: "img-sivaraj-bk.jpeg",
		linkedin: "https://www.linkedin.com/in/sivaraj-b-k-b56123152/",
		testid: "sivaraj",
	},
	srivalli: {
		name: "Srivalli Samineni",
		currentDesignation: "Senior Associate, VAHDAM",
		education: "Architecture, 2016, NIT Trichy",
		pronouns: PRONOUNS.SHE,
		image: "img-srivalli-samineni.jpeg",
		linkedin: "https://www.linkedin.com/in/srivallisamineni/",
		testid: "srivalli",
	},
	sowmya: {
		name: "Sowmyasri Muthupandi",
		currentDesignation: "Data Engineer at Meta",
		education: "Production, 2014, NIT Trichy",
		pronouns: PRONOUNS.SHE,
		image: "img-sowmyasri-muthupandi.jpeg",
		linkedin: "https://www.linkedin.com/in/sowmyasri/",
		testid: "sowmya",
	},
	varshith: {
		name: "Varshith Mahanthi",
		jspfDesignation: "Associate",
		currentDesignation: "ECE, 2024, NIT Trichy",
		education: "ECE, 2024, NIT Trichy",
		pronouns: PRONOUNS.HE,
		image: "img-varshith-mahanthi.jpg",
		linkedin: "https://www.linkedin.com/in/varshith-mahanthi-7ab202214/",
		testid: "varshith",
	},
	vivek: {
		name: "Vivek Shah",
		jspfDesignation: "Advisor",
		currentDesignation: "VP, Goldman Sachs",
		education: "CSE, 2008, NIT Trichy",
		pronouns: PRONOUNS.HE,
		image: "img-vivek-shah.jpeg",
		linkedin: "https://www.linkedin.com/in/viveknshah/",
		testid: "vivek",
	},
	wahaj: {
		name: "Wahaj Siddiqui",
		currentDesignation: "Economist, Airfinity",
		education: "Chemical, 2016, NIT Trichy",
		pronouns: PRONOUNS.HE,
		image: "img-wahaj-siddiqui.jpeg",
		linkedin: "https://www.linkedin.com/in/wahajsid/",
		testid: "wahaj",
	},
	zafar: {
		name: "Zafar Hussain",
		currentDesignation: "Strategy Lead, ICC",
		education: "Production, 2010, NIT Trichy",
		pronouns: PRONOUNS.HE,
		image: "img-zafar-hussain.jpeg",
		linkedin: "https://www.linkedin.com/in/zafarhussainsm/",
		testid: "zafar",
	},
	ashwini: {
		name: "Ashwini Petchiappan",
		currentDesignation: "Programme Assistant, Dakshin Foundation",
		education: "Civil, 2017, NIT Trichy",
		pronouns: PRONOUNS.SHE,
		image: "",
		linkedin: "https://www.linkedin.com/in/ashwinipetchiappan/",
		testid: "ashwini",
	},
	dhruv: {
		name: "Dhruv Kamath",
		currentDesignation: "Technical Product Manager, SandboxAQ",
		education: "Production, 2016, NIT Trichy",
		pronouns: PRONOUNS.HE,
		image: "",
		linkedin: "https://www.linkedin.com/in/ashwinipetchiappan/",
		testid: "dhruv",
	},
	shriya: {
		name: "Shriya Suriyanarayanan",
		currentDesignation: "MBA, Ross School of Business",
		education: "ICE, 2018, NIT Trichy",
		pronouns: PRONOUNS.SHE,
		image: "",
		linkedin: "https://www.linkedin.com/in/shriyasu/",
		testid: "shriya",
	},
	viswesh: {
		name: "Viswesh Warrier",
		currentDesignation: "Architectural Graduate, fitzpatrick+Partners",
		education: "Architecture, 2017, NIT Trichy",
		pronouns: PRONOUNS.HE,
		image: "",
		linkedin: "https://www.linkedin.com/in/visweshkwarrier/",
		testid: "viswesh",
	},
	raghu: {
		name: "Raghu Ram SS",
		currentDesignation: "Sr. Product Manager, Amazon",
		education: "Civil, 2014, NIT Trichy",
		pronouns: PRONOUNS.HE,
		image: "",
		linkedin: "https://www.linkedin.com/in/raghuramss/",
		testid: "raghu",
	},
	lakshmi: {
		name: "Lakshmi K Ajayan",
		currentDesignation: "Architectural Graduate, fitzpatrick+Partners",
		education: "Architecture, 2017, NIT Trichy",
		pronouns: PRONOUNS.SHE,
		image: "",
		linkedin: "https://www.linkedin.com/in/lakshmi-k-ajayan/",
		testid: "lakshmi",
	},
	ayushi: {
		name: "Ayushi Tiwari",
		currentDesignation: "Project Scientist, CSIR",
		education: "Civil, 2016, NIT Trichy",
		pronouns: PRONOUNS.SHE,
		image: "",
		linkedin: "https://www.linkedin.com/in/ayushitiwari/",
		testid: "ayushi",
	},
	siddhant: {
		name: "Siddhant Chandra",
		currentDesignation:
			"Public Policy Designer/Researcher, Dialogue and Development Commission Of Delhi",
		education: "Civil, 2016, NIT Trichy",
		pronouns: PRONOUNS.HE,
		image: "",
		linkedin: "https://www.linkedin.com/in/siddhant-chandra-5a8a0667/",
		testid: "siddhant",
	},
	cibi: {
		name: "Cibi Pranav",
		currentDesignation: "Digital Analyst/ Programmer, Arcadis",
		education: "Civil, 2015, NIT Trichy",
		pronouns: PRONOUNS.HE,
		image: "img-cibi-pranav.jpeg",
		linkedin: "https://www.linkedin.com/in/cibi-pranav-72a66a51/",
		testid: "cibi",
	},
	sudharshan: {
		name: "Sudharshan Suresh",
		currentDesignation: "PHD in Robotics, Carnegie Mellon University",
		education: "ICE, 2017, NIT Trichy",
		pronouns: PRONOUNS.HE,
		image: "",
		linkedin: "https://www.linkedin.com/in/sudharshansuresh/",
		testid: "sudharshan",
	},
	rachana: {
		name: "Rachana Ramchand",
		currentDesignation: "Chief Investment Officer's team, CDC Group plc",
		education: "CSE, 2012, NIT Trichy",
		pronouns: PRONOUNS.SHE,
		image: "",
		linkedin: "https://www.linkedin.com/in/rachanaramchand/",
		testid: "rachana",
	},
	padmapriya: {
		name: "Padmapriya Duraisamy",
		currentDesignation: "Software Engineer, Google",
		education: "ECE, 2014, NIT Trichy",
		pronouns: PRONOUNS.SHE,
		image: "",
		linkedin: "https://www.linkedin.com/in/pduraisamy/",
		testid: "padmapriya",
	},
	vrindaa: {
		name: "Vrindaa S",
		currentDesignation: "PHD, MIT",
		education: "MME, 2016, NIT Trichy",
		pronouns: PRONOUNS.SHE,
		image: "",
		linkedin: "https://www.linkedin.com/in/vrindaa-s-34646185/",
		testid: "vrindaa",
	},
	soundarya: {
		name: "Soundarya Balasubramani",
		currentDesignation: "Writer, Self-employed",
		education: "Chemical, 2017, NIT Trichy",
		pronouns: PRONOUNS.SHE,
		image: "",
		linkedin: "https://www.linkedin.com/in/soundarya-balasubramani/",
		testid: "soundarya",
	},
	jai: {
		name: "Jai Kesav K R",
		currentDesignation: "Student",
		education: "B.Tech, NIT Trichy",
		pronouns: PRONOUNS.HE,
		image: "img-jai-kesav-kr.png",
		linkedin: "https://www.linkedin.com/in/jai-kesav-k-r-400870195/",
		testid: "jai",
	},
	nithin: {
		name: "Nithin Balaaji D V",
		currentDesignation: "Manager, P&G",
		education: "B.Tech, NIT Trichy",
		pronouns: PRONOUNS.HE,
		image: "img-nithin-balaaji.png",
		linkedin: "https://www.linkedin.com/in/nithin-balaaji/",
		testid: "nithin",
	},
	swedha: {
		name: "Swedha S",
		currentDesignation: "PhD Candidate at TU/e",
		education: "B.Tech, NIT Trichy",
		pronouns: PRONOUNS.SHE,
		image: "img-swedha-s.png",
		linkedin: "https://www.linkedin.com/in/swedha-s/",
		testid: "swedha",
	},
	gudapati: {
		name: "Gudapati Nitish",
		currentDesignation: "Graduate Student at Stanford University",
		education: "Mechanical Engineering, 2021, NIT Trichy",
		pronouns: PRONOUNS.HE,
		image: "",
		linkedin: "https://www.linkedin.com/in/gudapati-nitish/",
		testid: "gudapati",
	},
	kishore: {
		name: "Kishore V",
		currentDesignation: "Technology Associate, Morgan Stanley",
		education: "CSE, 2021, NIT Trichy",
		pronouns: PRONOUNS.HE,
		image: "",
		linkedin: "https://www.linkedin.com/in/kishorehere/",
		testid: "kishore",
	},
	manu: {
		name: "Manu Aatitya R P",
		currentDesignation: "Embedded Software Engineer, Nymble",
		education: "Mechanical Engineering, 2021, NIT Trichy",
		pronouns: PRONOUNS.HE,
		image: "",
		linkedin: "https://www.linkedin.com/in/manu-aatitya-r-p-78457412b/",
		testid: "manu",
	},
	shiwang: {
		name: "Shiwang Singh",
		currentDesignation: "Technology Analyst, Citi",
		education: NittBranches.CIV,
		pronouns: PRONOUNS.HE,
		image: "",
		linkedin: "",
		testid: "shiwang",
	},
	srinivas: {
		name: "Srinivas R",
		currentDesignation: "Business Operations Associate at ZS",
		education: "Civil Engineering, 2021, NIT Trichy",
		pronouns: PRONOUNS.HE,
		image: "",
		linkedin: "https://www.linkedin.com/in/srinivasrnitt/",
		testid: "shiwang",
	},
	darshan: {
		name: "Darshan Maru",
		jspfDesignation: "Senior Associate",
		currentDesignation: "Product Manager",
		education: NittBranches.MME,
		pronouns: PRONOUNS.HE,
		image: "img-darshan-maru.jpeg",
		linkedin: "https://www.linkedin.com/in/darshanmaru/",
		testid: "darshan",
	},
	akshaya: {
		name: "Akshaya Subramanian",
		currentDesignation: "Student",
		education: NittBranches.ICE,
		pronouns: PRONOUNS.SHE,
		image: "",
		linkedin: "https://www.linkedin.com/in/akshaya-subramanian-22020/",
		testid: "akshaya",
	},
	anagha: {
		name: "Anagha Sethuraman",
		currentDesignation: "Student",
		education: NittBranches.ECE,
		pronouns: PRONOUNS.SHE,
		image: "",
		linkedin: "https://www.linkedin.com/in/anagha-sethuraman-192148179/",
		testid: "anagha",
	},
	ashutosh: {
		name: "Ashutosh Verma",
		currentDesignation: "Student",
		education: NittBranches.CIV,
		pronouns: PRONOUNS.HE,
		image: "",
		linkedin: "https://www.linkedin.com/in/ashutosh-verma-478ba5183/",
		testid: "ashutosh",
	},
	jayamathangi: {
		name: "Jayamathangi S",
		currentDesignation: "Student",
		education: NittBranches.EEE,
		pronouns: PRONOUNS.SHE,
		image: "",
		linkedin: "https://www.linkedin.com/in/jayamathangi-s-3464a8184/",
		testid: "jayamathangi",
	},
	madhav: {
		name: "Madhav Aggarwal",
		currentDesignation: "Student",
		education: NittBranches.CSE,
		pronouns: PRONOUNS.HE,
		image: "",
		linkedin: "https://www.linkedin.com/in/madhav-aggarwal/",
		testid: "madhav",
	},
	saik: {
		name: "Sai Karthik K A",
		currentDesignation: "Technology Analyst - AI, Citi, Pune",
		education: NittBranches.CIV,
		pronouns: PRONOUNS.HE,
		image: "",
		linkedin: "https://www.linkedin.com/in/kasaikarthik/",
		testid: "saik",
	},
	bksivaraj: {
		name: "B K Sivaraj",
		currentDesignation: "Student",
		education: NittBranches.MME,
		pronouns: PRONOUNS.HE,
		image: "",
		linkedin: "https://www.linkedin.com/in/sivaraj-b-k-b56123152/",
		testid: "bksivaraj",
	},
	subhasree: {
		name: "G Subhasree",
		currentDesignation: "Student",
		education: NittBranches.CSE,
		pronouns: PRONOUNS.SHE,
		image: "",
		linkedin: "https://www.linkedin.com/in/subhasree-g-62a7a51a4/",
		testid: "subhasree",
	},
	haemanth: {
		name: "Haemanth V",
		currentDesignation: "Student",
		education: NittBranches.CSE,
		pronouns: PRONOUNS.HE,
		image: "",
		linkedin: "https://www.linkedin.com/in/haemanth-v-951176191/",
		testid: "haemanth",
	},
	jeremiah: {
		name: "Jeremiah Thomas",
		currentDesignation: "Student",
		education: NittBranches.CSE,
		pronouns: PRONOUNS.HE,
		image: "",
		linkedin: "https://www.linkedin.com/in/jeremiahthomas14/",
		testid: "jeremiah",
	},
	shreyas: {
		name: "K Shreyas",
		currentDesignation: "Student",
		education: NittBranches.CSE,
		pronouns: PRONOUNS.HE,
		image: "",
		linkedin: "https://www.linkedin.com/in/shreyas-k-029b35110",
		testid: "shreyas",
	},
	abhishek: {
		name: "K S Abhishek",
		currentDesignation: "Student",
		education: NittBranches.MEC,
		pronouns: PRONOUNS.HE,
		image: "",
		linkedin: "https://www.linkedin.com/in/k-s-abhishek-a507921a4",
		testid: "abhishek",
	},
	akhilesh: {
		name: "Akhilesh C",
		currentDesignation: "Student",
		education: NittBranches.MEC,
		pronouns: PRONOUNS.HE,
		image: "",
		linkedin: "https://www.linkedin.com/in/akhilesh-chandrasekhar",
		testid: "akhilesh",
	},
	anushree: {
		name: "Anushree Appandairajan",
		currentDesignation: "GET, John Deere",
		education: NittBranches.EEE,
		pronouns: PRONOUNS.SHE,
		image: "",
		linkedin: "https://www.linkedin.com/in/anushree-appandairajan-0aa134205",
		testid: "anushree",
	},
	ashwath: {
		name: "Ashwath V",
		currentDesignation: "Student",
		education: NittBranches.CSE,
		pronouns: PRONOUNS.HE,
		image: "",
		linkedin: "https://www.linkedin.com/in/ashwath-vasudevan-92282321a",
		testid: "ashwath",
	},
	ghantasala: {
		name: "Ghantasala Harshith",
		jspfDesignation: "Associate",
		currentDesignation: "Civil, 2024, NIT Trichy",
		education: NittBranches.CIV,
		pronouns: PRONOUNS.HE,
		image: "img-ghantasala-harshith.jpg",
		linkedin: "https://www.linkedin.com/in/harshith-ghantasala",
		testid: "ghantasala",
	},
	nagavalli: {
		name: "HRSS Nagavalli Chatla",
		currentDesignation: "Student",
		education: NittBranches.EEE,
		pronouns: PRONOUNS.SHE,
		image: "",
		linkedin: "https://www.linkedin.com/in/nagavalli-chatla-b53175213/",
		testid: "nagavalli",
	},
	mainak: {
		name: "Mainak Khan",
		currentDesignation: "Student",
		education: NittBranches.MME,
		pronouns: PRONOUNS.HE,
		image: "",
		linkedin: "https://www.linkedin.com/in/mainak-khan-88a850178/",
		testid: "mainak",
	},
	naresh: {
		name: "Naresh B",
		currentDesignation: "Student",
		education: NittBranches.CHE,
		pronouns: PRONOUNS.HE,
		image: "",
		linkedin: "https://www.linkedin.com/in/naresh-b-72a366202/",
		testid: "naresh",
	},
	thivyaa: {
		name: "Thivyaa Mohan",
		jspfDesignation: "Associate",
		currentDesignation: "Civil, 2023, NIT Trichy",
		education: "Civil, 2023, NIT Trichy",
		pronouns: PRONOUNS.SHE,
		image: "img-thivyaa-mohan.jpeg",
		linkedin: "https://www.linkedin.com/in/thivyaamohan-nitt/",
		testid: "thivyaa",
	},
	vaikundaraman: {
		name: "Vaikundaraman T S",
		jspfDesignation: "Associate",
		currentDesignation: NittBranches.MME,
		education: NittBranches.MME,
		pronouns: PRONOUNS.HE,
		image: "img-vaikundaraman-t-s.jpeg",
		linkedin: "https://www.linkedin.com/in/vaikundaraman-t-s-50aaa0224/",
		testid: "vaikundaraman",
	},
	harshavardhan: {
		name: "Harshavardhan R",
		jspfDesignation: "Associate",
		currentDesignation: "Civil, 2025, NIT Trichy",
		education: "Civil, 2025, NIT Trichy",
		pronouns: PRONOUNS.HE,
		image: "img-harshavardhan-r.jpeg",
		linkedin: "https://www.linkedin.com/in/harshavardhan-r-a1ab6524b/",
		testid: "harshavardhan",
	},
	shakti: {
		name: "Shakti Chaudhari",
		jspfDesignation: "Associate",
		currentDesignation: "Mech, MMCOE, 2022",
		education:
			"BE, Mech, Marathwada Mitra Mandal's College of Engineering, Pune",
		pronouns: PRONOUNS.HE,
		image: "img-shakti-chaudhari.jpeg",
		linkedin: "https://www.linkedin.com/in/shakti-chaudhari-1392121a4",
		testid: "shakti",
	},
	siva: {
		name: "Sivaranjani Subramanian",
		jspfDesignation: "Founder and Executive Director",
		currentDesignation: "Software Engineer, Thoughtworks UK",
		education: "CSE, 2015, NIT Trichy",
		pronouns: PRONOUNS.SHE,
		image: "img-sivaranjani-subramanian.jpeg",
		linkedin: "https://www.linkedin.com/in/sranjani",
		testid: "siva",
	},
	mrperiasamy: {
		name: "Mr. Periasamy",
		currentDesignation: "Father of Jitheshraj Periasamy",
		testid: "mrperiasamy",
	},
	drruckmani: {
		name: "Dr. Ruckmani Kandasamy",
		currentDesignation: "Mother of Jitheshraj Periasamy",
		testid: "drruckmani",
	},
	dranantharaman: {
		name: "Dr. Anantharaman",
		currentDesignation:
			"HOD, Chemical Engineering, Alumni-Institute Interaction Cell, NIT Trichy",
		testid: "dranantharaman",
	},
	drbakthavatsalam: {
		name: "Dr. Bakthavatsalamn",
		currentDesignation: "Training and Placement, CEESAT - NIT Trichy",
		testid: "drbakthavatsalam",
	},
	drsubbaiyan: {
		name: "Dr. Subbaiyan",
		currentDesignation:
			"Architecture, Alumni-Institute Interaction Cell, NIT Trichy",
		testid: "drsubbaiyan",
	},
	sarath: {
		name: "Sarath Haridas",
		testid: "sarath",
	},
	deepaknd: {
		name: "Deepak N D",
		testid: "deepaknd",
	},
	snigdha: {
		name: "Snigdha Nagarajan",
		testid: "snigdha",
	},
	deepan: {
		name: "Deepan Thiruppathy",
		testid: "deepan",
	},
	anupriya: {
		name: "Anupriya Komeswaran",
		testid: "anupriya",
	},
	nirmal: {
		name: "Nirmal Kumar",
		testid: "nirmal",
	},
	kasarla: {
		name: "Kasarla Mukthi",
		currentDesignation: "Associate User Experience Designer",
		education: "B.Des in Fashion Communication",
		pronouns: PRONOUNS.SHE,
		image: "img-kasarla-mukthi.jpg",
		linkedin: "https://www.linkedin.com/in/mukthikasarla",
		testid: "kasarla",
	},
	Ajays: {
		name: "Ajay Sapkale",
		currentDesignation: "Co-founder at Astrophilia Ed",
		education: "B.Des, Industrial and Product Design",
		pronouns: PRONOUNS.HE,
		image: "img-ajay-sapkale.jpg",
		linkedin: "https://www.linkedin.com/in/ajay-sapkale-2b06a5183",
		testid: "Ajays",
	},
	gayathri: {
		name: "Gayathri Rajesh",
		currentDesignation: "Student",
		education: "B.Tech, NIT Trichy",
		pronouns: PRONOUNS.SHE,
		image: "img-gayathri-rajesh.jpg",
		linkedin: "https://www.linkedin.com/in/gayathri-rajesh-b84560228/",
		testid: "gayathri",
	},
	vibhak: {
		name: "Vibha Kaushik",
		currentDesignation: "Student",
		education: NittBranches.PRO,
		pronouns: PRONOUNS.SHE,
		linkedin: "https://www.linkedin.com/in/vibha-kaushik",
		testid: "vibhak",
	},
	rharshvardhan: {
		name: "R Harshvardhan",
		currentDesignation: "Student",
		education: NittBranches.CIV,
		pronouns: PRONOUNS.HE,
		linkedin: "https://www.linkedin.com/in/harshavardhan-r-a1ab6524b/",
		testid: "rharshvardhan",
	},
	aadarsh: {
		name: "Aadarsh A",
		currentDesignation: "Student",
		education: NittBranches.CSE,
		pronouns: PRONOUNS.HE,
		linkedin: "https://www.linkedin.com/in/aadarsh-a",
		testid: "aadarsh",
	},
	devb: {
		name: "Dev Bhandari",
		currentDesignation: "Student",
		education: NittBranches.ICE,
		pronouns: PRONOUNS.HE,
		linkedin: "https://www.linkedin.com/in/dev-bhandari-51b462227",
		testid: "devb",
	},
	srikants: {
		name: "Srikant Sharma",
		currentDesignation: "Student",
		education: NittBranches.CSE,
		pronouns: PRONOUNS.HE,
		linkedin: "https://www.linkedin.com/in/srikanth-v-2a74b117a",
		testid: "srikants",
	},
	kotilingesh: {
		name: "Kotilingesh",
		currentDesignation: "Student",
		education: NittBranches.MEC,
		pronouns: PRONOUNS.HE,
		linkedin: "https://www.linkedin.com/in/dev-bhandari-51b462227",
		testid: "kotilingesh",
	},
	anmol: {
		name: "Anmol Panda",
		currentDesignation: "MSc in Energy Sciences and Technology, ETH Zurich",
		education: "B.Tech, " + NittBranches.MEC + ", NITT",
		pronouns: PRONOUNS.SHE,
		image: "img-anmol-panda.jpg",
		linkedin: "https://www.linkedin.com/in/anmol-panda-368382179",
		testid: "anmol",
	},
	suyash: {
		name: "Suyash S. Damir",
		currentDesignation: "MSc in Chemical and Bioengineering, ETH Zurich",
		education: "B.Tech, " + NittBranches.CHE + ", NITT",
		pronouns: PRONOUNS.HE,
		image: "img-suyash-damir.jpg",
		linkedin: "https://www.linkedin.com/in/suyashsachindamir/",
		testid: "suyash",
	},
	monica: {
		name: "Monica Roy Chowdhury",
		currentDesignation: "Analyst - Oil Operations, Trafigura",
		education: "B.Arch, NITT",
		pronouns: PRONOUNS.SHE,
		image: "img-monica-roy.jpeg",
		linkedin: "https://www.linkedin.com/in/monica-roy-chowdhury-046660128/",
		testid: "monica",
	},
	sneha: {
		name: "Sneha Menon",
		currentDesignation: "Content Strategist, Stacker",
		education: "B.Arch, NITT",
		pronouns: PRONOUNS.SHE,
		image: "img-sneha-menon.jpeg",
		linkedin: "https://www.linkedin.com/in/menonsneha/",
		testid: "sneha",
	},
	hrishit: {
		name: "Hrishit Mitra",
		currentDesignation: "Student",
		education: "B.Tech, NIT Trichy",
		pronouns: PRONOUNS.HE,
		image: "img-hrishit-mitra.jpg",
		linkedin: "https://www.linkedin.com/in/hrishit-mitra-135399297/",
		testid: "hrishit",
	},
	madhumathi: {
		name: "Madhumathi S",
		currentDesignation: "Student",
		education: "B.Des, DOT Schoool of Design",
		pronouns: PRONOUNS.SHE,
		image: "img-madhumathi-srinivasan.jpg",
		linkedin: "https://www.linkedin.com/in/madhumathi-srinivasan-158a15244/",
		testid: "madhumathi",
	},
	anushka: {
		name: "Anushka Gangil",
		currentDesignation: "Student",
		education: "B.Des, Symbiosis Institute of Design",
		pronouns: PRONOUNS.SHE,
		linkedin: "https://www.linkedin.com/in/anushka-gangil-308780254/",
		testid: "anushka",
	},
	dharaneesh: {
		name: "Dharaneesh S A",
		currentDesignation: "Student",
		education: "B.Tech, Madras Institute of Technology",
		pronouns: PRONOUNS.HE,
		linkedin: "https://www.linkedin.com/in/anushka-gangil-308780254/",
		testid: "dharaneesh",
	},
	swapnali: {
		name: "Swapnali Patil",
		currentDesignation: "Student",
		education: "B.Des, Srishti Manipal Institute of Art Design and Technology",
		pronouns: PRONOUNS.SHE,
		linkedin: "https://www.linkedin.com/in/swapnalipatil10/",
		testid: "swapnali",
	},
	kirthik: {
		name: "Kirthik M",
		currentDesignation: "Student",
		education: "B.Tech, NIT Trichy",
		pronouns: PRONOUNS.HE,
		linkedin: "https://www.linkedin.com/in/kirthikm/",
		testid: "kirthik",
	},
	snehak: {
		name: "Sneha Koley",
		currentDesignation: "Student",
		education: "B.Tech, NIT Trichy",
		pronouns: PRONOUNS.SHE,
		linkedin: "https://www.linkedin.com/in/sneha-koley/",
		testid: "snehak",
	},
	saurabh: {
		name: "Saurabh Kumar Jha",
		currentDesignation: "Student",
		education: "B.Tech, NIT Trichy",
		pronouns: PRONOUNS.HE,
		linkedin: "https://www.linkedin.com/in/sneha-koley/",
		testid: "saurabh",
	},
	harsh: {
		name: "Harsh Deshpande",
		currentDesignation: "Student",
		education: "B.Tech, NIT Trichy",
		pronouns: PRONOUNS.HE,
		linkedin: "https://www.linkedin.com/in/hdeshpande1211/",
		testid: "harsh",
	},
	senthoorvel: {
		name: "Senthoorvel P",
		currentDesignation: "Student",
		education: "B.Tech, NIT Trichy",
		pronouns: PRONOUNS.HE,
		linkedin: "https://www.linkedin.com/in/senthoorvel-p/",
		testid: "senthoorvel",
	}
}
